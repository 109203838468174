<template>
    <div>
        <div class="row row_item" v-for="(item, index) in productos" :key="index">
	        <div class="col-sm-4">
	            <figure>
					<span v-if="item.estadoPresentacion === 'NUEVO'" class="ribbon hot">Nuevo</span>
                    <span v-if="item.estadoPresentacion === 'DESCUENTO'" class="ribbon off">Desc %</span>
                    <span v-if="item.estadoPresentacion === 'OFERTA'" class="ribbon new">Oferta</span>
	                <router-link :to="{ name: 'productowebsite', params: {slug: item.slug} }">
	                    <img loading="lazy" class="img-fluid lazy" :alt="item.nombre" :src="imagenCargada ? item.imagen : '/static/assets/images/loading.gif'" @load="imagenCargada = true">
	                </router-link>
	            </figure>
	        </div>
	        <div class="col-sm-8">
	            <div class="rating">
                    <i class="icon-star voted"></i>
                    <i class="icon-star voted"></i>
                    <i class="icon-star voted"></i>
                    <i class="icon-star voted"></i>
                    <i class="icon-star voted"></i>
                </div>
	            <router-link :to="{ name: 'productowebsite', params: {slug: item.slug} }">
	                <h3>{{ item.nombreLargo }}</h3>
	            </router-link>
				<p>{{ item.descripcion }}</p>
	            <div class="price_box">
	                <template v-if="item.estadoPrecioVenta === 'HABILITADO'">
                        <template v-if="item.estadoPresentacion === 'DESCUENTO'">
                            <span class="new_price">Bs.{{ item.precioVentaDescuento }}</span>
                            <span class="old_price" v-if="item.estadoPresentacion === 'DESCUENTO'">Bs.{{ item.precioVentaOriginal }}</span>
                        </template>
                        <template v-else>
                            <span class="new_price">Bs.{{ item.precioVentaOriginal }}</span>
                        </template>
                    </template>
	            </div>
	            <ul>
	                <li>
						<router-link class="btn_1" :to="{ name: 'productowebsite', params: {slug: item.slug} }">
                            <a-icon type="shopping-cart" :style="{ fontSize: '17px' }"/> Agregar al Carrito
                        </router-link>
					</li>
					<li>
						<router-link :to="{ name: 'productowebsite', params: {slug: item.slug} }">
                            <a-button type="dashed" size="large" block>
                                <a-icon type="eye" :style="{ fontSize: '20px' }"/> Ver detalle
                            </a-button>
                        </router-link>
					</li>
	            </ul>
	        </div>
	    </div>
    </div>
</template>

<script>
	import { mapGetters, mapActions } from 'vuex'
	import * as utils from '@/utils/utils'
	import router from '@/config/router'

	export default {

		components: {

		},

		data () {
			return {
                utils,
				imagenCargada: false
			}
		},

		computed: {
			...mapGetters('producto', ['productos'])
		},

		methods: {

		},

        mounted () {
			
		}
	}
</script>