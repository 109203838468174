<template>
    <div>
        <a-carousel autoplay>
            <img loading="lazy" src="/static/website/img/slider1.png" alt="1">
            <img loading="lazy" src="/static/website/img/slider2.png" alt="2">
            <img loading="lazy" src="/static/website/img/slider3.png" alt="3">
            <img loading="lazy" src="/static/website/img/slider1.png" alt="4">
        </a-carousel>
    </div>
</template>

<script>
	import { mapGetters, mapActions } from 'vuex'
	import * as utils from '@/utils/utils'
	import router from '@/config/router'

	export default {

		components: {
			
		},

		data () {
			return {
                utils,
				imagenCargada: false
			}
		},

		computed: {
			
		},

		methods: {

		},

        mounted () {
			
		}
	}
</script>