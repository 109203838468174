<template>
    <div>
        <a-carousel autoplay>
			<img loading="lazy" src="/static/website/img/banner15.jpg" alt="1">
			<img loading="lazy" src="/static/website/img/banner15.jpg" alt="2">
			<img loading="lazy" src="/static/website/img/banner15.jpg" alt="3">
			<img loading="lazy" src="/static/website/img/banner15.jpg" alt="4">
		</a-carousel>
    </div>
</template>

<script>
	import { mapGetters, mapActions } from 'vuex'
	import * as utils from '@/utils/utils'
	import router from '@/config/router'

	export default {

		components: {
			
		},

		data () {
			return {
                utils,
				imagenCargada: false
			}
		},

		computed: {
			
		},

		methods: {

		},

        mounted () {
			
		}
	}
</script>