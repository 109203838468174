<template>
    <div>
        <div class="row small-gutters">
            <div class="col-6 col-md-4 col-xl-3" v-for="(item, index) in productos" :key="index">
                    <div class="grid_item">
                        <span v-if="item.estadoPresentacion === 'NUEVO'" class="ribbon hot">Nuevo</span>
                        <span v-if="item.estadoPresentacion === 'DESCUENTO'" class="ribbon off">- {{ item.descuento }}%</span>
                        <span v-if="item.estadoPresentacion === 'OFERTA'" class="ribbon new">Oferta</span>
                        <figure>
                            <router-link :to="{ name: 'productowebsite', params: {slug: item.slug} }">
                                <img loading="lazy" class="img-fluid lazy img-ecommerce" :alt="item.nombre" :src="imagenCargada ? item.imagen : '/static/assets/images/loading.gif'" @load="imagenCargada = true">
                            </router-link>
                        </figure>
                        <div class="rating">
                            <i class="icon-star voted"></i>
                            <i class="icon-star voted"></i>
                            <i class="icon-star voted"></i>
                            <i class="icon-star voted"></i>
                            <i class="icon-star voted"></i>
                        </div>
                        <router-link :to="{ name: 'productowebsite', params: {slug: item.slug} }">
                            <h3 class="titulo-ecommerce">{{ item.nombre }}</h3>
                        </router-link>
                        <div class="price_box">
                            <template v-if="item.estadoPrecioVenta === 'HABILITADO'">
                                <template v-if="item.estadoPresentacion === 'DESCUENTO'">
                                    <span class="new_price">Bs.{{ item.precioVentaDescuento }}</span>
                                    <span class="old_price" v-if="item.estadoPresentacion === 'DESCUENTO'">Bs.{{ item.precioVentaOriginal }}</span>
                                </template>
                                <template v-else>
                                    <span class="new_price">Bs.{{ item.precioVentaOriginal }}</span>
                                </template>
                            </template>
                            <template v-if="item.estadoPrecioVenta === 'DESHABILITADO'">
                                <br>
                            </template>
                            <a-row type="flex">
                                <a-col flex="1 1 100px" :span="24">
                                    <a-button type="primary" size="large" block>
                                        <a-icon type="shopping-cart" :style="{ fontSize: '17px', marginLeft: '-10px' }"/>Agregar al Carrito
                                    </a-button>
                                </a-col>
                                <a-col flex="1 1 100px" :span="24">
                                    <router-link :to="{ name: 'productowebsite', params: {slug: item.slug} }">
                                        <a-button type="dashed" size="small" block>
                                            <a-icon type="eye" :style="{ fontSize: '15px' }"/> Ver detalle
                                        </a-button>
                                    </router-link>
                                </a-col>
                            </a-row>
                        </div>
                    </div>
            </div>
        </div>
    </div>
</template>

<script>
	import { mapGetters, mapActions } from 'vuex'
	import * as utils from '@/utils/utils'
	import router from '@/config/router'

	export default {

		components: {

		},

		data () {
			return {
                utils,
				imagenCargada: false
			}
		},

		computed: {
			...mapGetters('producto', ['productos'])
		},

		methods: {

		},

        mounted () {
			
		}
	}
</script>

<style>
.ant-btn > .anticon + span, .ant-btn > span + .anticon {
    margin-left: 3px !important;
}
</style>