<template>
    <div>
        <a-menu
			mode="inline"
			:open-keys="openKeys"
			style="height: 100%"
			@openChange="onOpenChange"
			>
			<a-menu-item v-for="value in marcas.slice(0, 12)" :key="value.marca_id" @click="openMenu(value.slug)">
				<a-icon type="line" :style="{ fontSize: '12px' }"/> <span style="color:black; font-size: 15px;">{{ value.nombre }}</span><span class="indicador">{{ value.totalProductos }}</span>
			</a-menu-item>
		</a-menu>
		<center>
			<a-button 
				type="primary" 
                block 
                size="small"
                @click.prevent="openModal()"
                :key="keyButton"
				>
				Ver más marcas
			</a-button>
		</center>

		<a-modal
            v-model="modal"
            :title="false"
            :closable="true"
            :footer="false"
            :destroyOnClose="false"
            :maskClosable="true"
            :dialog-style="{ top: '50px' }"
            :width="420"
            :zIndex="1040"
            >
    
            <div class="row mb-12">
                <label class="control-label" style="font-weight: 700;">Seleccione una Marca</label>
                <a-select  size="large" :value="form.marcaID" @change="onSelect" placeholder="Seleccionar" show-search option-filter-prop="children" :filter-option="filterOption" style="width: 100%">
                    <a-select-option v-for="(value, index) in marcas" :key="index" :value="value.marca_id"> {{ value.nombre }}</a-select-option>
                </a-select>
            </div>
        </a-modal>
    </div>
</template>

<script>
	import { mapGetters, mapActions } from 'vuex'
	import * as utils from '@/utils/utils'
	import router from '@/config/router'

	export default {

		components: {
			
		},

		data () {
			return {
                utils,
                openKeys: ['sub1'],
				keyButton: 10,
                modal: false,
                form: {
                    marcaID: 'Seleccionar'
                }
			}
		},

		computed: {
			...mapGetters('marca', ['marcas'])
		},

		methods: {

            filterOption (input, option) {
                return (
                    option.componentOptions.children[0].text.toUpperCase().indexOf(input.toUpperCase()) >= 0
                )
            },

            onSelect (value) {
                this.form.marcaID = value
            },

            openMenu (slug) {
                console.log(slug)
				// router.push({ name: name })
			},

            openModal () {
                this.modal = true
                this.keyButton++
            }

		},

        mounted () {
			
		}
	}
</script>

<style>
.ant-menu-item .indicador {
    display: inline-block;
    text-align: right !important; /* Alinea el texto a la derecha */
    margin-left: 20px;
}
</style>