<template>
    <div>
        <SliderPublicidadHeader />

        <div id="stick_here"></div>
        
        <div class="toolbox elemento_stick">
	        <div class="container">
	            <ul class="clearfix">
	                <li>
	                    <div class="sort_select">
	                        <select name="sort" id="sort">
	                            <option value="popularity" selected="selected">Ordenar por Nombre</option>
	                            <option value="rating">Ordernar por Marca</option>
	                            <option value="price">Ordernar por Precio - Menor a Mayor</option>
	                            <option value="price-desc">Ordernar por Precio - Mayor a Menor</option>
	                        </select>
	                    </div>
	                </li>
	                <li>
	                    <a href="#" @click.prevent="cambiarVistaProducto('view-grid')"><i class="ti-view-grid"></i></a>
	                    <a href="#" @click.prevent="cambiarVistaProducto('view-list')"><i class="ti-view-list"></i></a>
	                </li>
                    <li>
	                    <a href="#" class="open_filters">
	                        <i class="ti-filter"></i><span>Filtros</span>
	                    </a>
	                </li>
	            </ul>
	        </div>
	    </div>

        <div class="container margin_30">
	        <div class="row">
	            <aside class="col-lg-3" id="sidebar_fixed">
	                <div class="filter_col">
	                    <div class="inner_bt"><a href="#" class="open_filters"><i class="ti-close"></i></a></div>

						<div class="filter_type version_2">
	                        <h4><a href="#filter_1" data-bs-toggle="collapse" class="opened">Marcas</a></h4>
	                        <div class="collapse show" id="filter_1">
	                            <MenuMarca />
	                        </div>
	                    </div>

						<div class="filter_type version_2">
							<SliderPublicidadSidebarUp />
						</div>

						<div class="filter_type version_2">
							<SliderPublicidadSidebarDown />
						</div>
	                </div>
	            </aside>

	            <div class="col-lg-9">

                    <template v-if="vistaProducto === 'view-grid'">
                        <ProductoCard /><br>
                    </template>

                    <template v-if="vistaProducto === 'view-list'">
                        <ProductoList /><br>
                    </template>

					<center>
						<a-pagination
							show-size-changer
							v-model="paginate.current"
							:total="paginate.total"
							:page-size="paginate.pageSize"
							@showSizeChange="onShowSizeChange"
							@change="onChange"
							>
							<template slot="buildOptionText" slot-scope="props">
								<span v-if="props.value !== '100'">{{ props.value }} Páginas</span>
								<span v-if="props.value === '100'"> Todos</span>
							</template>
						</a-pagination>
					</center>
	                
	            </div>
	        </div>
	    </div>

        <SliderPublicidadFooter />

		<div v-if="spinnerloading == true" class="col-md-12" style="position: fixed; top: 0; right: 0; bottom: 0; left: 0; background: rgba(0, 0, 0, 0.5); z-index: 1150 !important;">
            <div style="margin-top: 250px;">
                <vue-simple-spinner :line-size="10" size="massive" text-fg-color="#f3f3f3" message="Procesando Información..."></vue-simple-spinner>
                <br><br>
                <center><button type="button" class="btn btn-warning btn-sm" title="Cancelar" @click="spinnerloading = false"><i class="fa fa-stop"></i> Cerrar Ventana de Espera</button></center>
            </div>
        </div>
    </div>
</template>

<script>
	import locale from 'ant-design-vue/es/date-picker/locale/es_ES'
	import moment from 'moment'
	import 'moment/locale/es'
	import { mapGetters, mapActions } from 'vuex'
	import * as utils from '@/utils/utils'
	import router from '@/config/router'
    import SliderPublicidadHeader from '../../components/Slider/SliderPublicidadHeader.vue'
	import SliderPublicidadFooter from '../../components/Slider/SliderPublicidadFooter.vue'
	import SliderPublicidadSidebarUp from '../../components/Slider/SliderPublicidadSidebarUp.vue'
    import SliderPublicidadSidebarDown from '../../components/Slider/SliderPublicidadSidebarDown.vue'
	import ProductoCard from '../../components/Producto/ProductoCard.vue'
    import ProductoList from '../../components/Producto/ProductoList.vue'
	import MenuMarca from '../../components/Marca/MenuMarca.vue'

	export default {

		components: {
			SliderPublicidadHeader,
			SliderPublicidadFooter,
			SliderPublicidadSidebarUp,
			SliderPublicidadSidebarDown,
            ProductoCard,
            ProductoList,
			MenuMarca
		},

		data () {
			return {
                dateFormat: 'YYYY-MM-DD',
                utils,
				moment,
				locale,
				imagenCargada: false,
                lista_ordenar: [
                    { id: 1, nombre: 'Ordernar por Nombre' },
                    { id: 2, nombre: 'Ordernar por Marca' },
                    { id: 3, nombre: 'Ordernar por Precio' },
                    { id: 4, nombre: 'Ordernar por Precio - Menor a Mayor' },
                    { id: 5, nombre: 'Ordernar por Precio - Mayor a Menor' }
                ],
                consulta: {
					almacenID: '',
					marcaID: '',
					categoriaID: '',
					subcategoriaID: '',
                    ordenar: ''
                },
                vistaProducto: '',
				paginate: {
                    pageSize: 20,
                    current: 1,
                    total: 0,
                    field: 'producto_id',
                    order: 'descend'
                },
				spinnerloading: false
			}
		},

		computed: {
			...mapGetters('almacen', ['almacen'])
		},

		methods: {
			...mapActions('producto', ['getProductos']),

            cambiarVistaProducto (value) {
                localStorage.setItem('vistaProducto', value)
                this.vistaProducto = value
            },

            changePaginate (data) {
                this.paginate = {
                    pageSize: data.per_page,
                    current: data.current_page,
                    total: data.total,
                    field: 'producto_id',
                    order: 'descend'
                }
            },

			onShowSizeChange (current, pageSize) {
				this.paginate = {
                    pageSize: pageSize,
                    current: current,
                    total: this.paginate.total,
                    field: 'producto_id',
                    order: 'descend'
                }
                this.paginarResultados()
			},

			onChange (page, pageSize) {
				this.paginate = {
                    pageSize: pageSize,
                    current: page,
                    total: this.paginate.total,
                    field: 'producto_id',
                    order: 'descend'
                }
                this.paginarResultados()
			},

			paginarResultados () {
                this.spinnerloading = true
				this.consulta.almacenID = this.almacen.almacen_id
				
                this.getProductos({ ...this.paginate, ...this.consulta })
                .then(response => {
                    this.changePaginate(response.data.meta)
                    this.spinnerloading = false
                }).catch(error => {
                    this.loading = false
                    utils.openNotificationWithIcon('error', 'Error al enviar la Informacion', utils.showErrors(error.response.data), 'topRight')
                })
            }
		},

        mounted () {
			const vista = localStorage.getItem('vistaProducto')
			if (vista) {
				this.vistaProducto = vista
			} else {
				this.vistaProducto = 'view-grid'
			}
			
			this.$root.$on('llamarPaginarResultados', this.paginarResultados)
			setTimeout(() => { this.paginarResultados() }, 2000)
		},

		beforeDestroy () {
			this.$root.$off('llamarPaginarResultados', this.paginarResultados)
		}
	}
</script>